import { createMuiTheme } from '@material-ui/core/styles'

import { pink, blueGrey, red } from '@material-ui/core/colors'

const breakpointValues = {
  xs: 0,
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1920
}

export default createMuiTheme({
  palette: {
    primary: {
      ...blueGrey,
      main: blueGrey[500],
      contrastText: '#fff'
    },
    secondary: {
      ...pink,
      main: pink[800]
    },
    error: {
      ...red,
      main: red[700]
    }
  },
  breakpoints: { values: breakpointValues },
  typography: {
    subtitle2: {
      fontFamily: 'Times New Roman',
      fontSize: '1.5rem',
      fontWeight: 400
    },
    h6: {
      fontFamily: 'Arial',
      fontSize: '0.9rem'
    }
  }
})
