import React, { useState, useEffect } from 'react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress'
import { devEnvironment } from 'config'

const MainPage = React.lazy(() => import('pages/Main'))
const NotFoundPage = React.lazy(() => import('pages/NotFound'))

function Routing(): React.ReactElement {
  const [loader, setLoader] = useState(true)

  useEffect(() => {
    document.body.classList.add('overflow-hidden')
    const timer = setTimeout(() => {
      setLoader(false)
      document.body.classList.remove('overflow-hidden')
    }, 100)
    return (): void => {
      clearTimeout(timer)
    }
  }, [])

  return (
    <React.Suspense fallback={<CircularProgress />}>
      <BrowserRouter>
        <div className="app-wrapper">
          {loader && (
            <div className="loading">
              <CircularProgress />
            </div>
          )}
          <Switch>
            <Route
              path="/:productId/:pricePointId"
              render={(props): React.ReactElement => <MainPage {...props} />}
            />
            <Route
              path="/not-found"
              render={(props): React.ReactElement => (
                <NotFoundPage {...props} />
              )}
            />
            <Route
              path="/"
              render={() => {
                if (devEnvironment) {
                  return <Redirect to={'/5228069/925577'} />
                } else {
                  window.location.href = 'https://99walks.fit'
                }
              }}
            />
          </Switch>
        </div>
      </BrowserRouter>
    </React.Suspense>
  )
}

export default Routing
