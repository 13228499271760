import React from 'react'
import { ThemeProvider } from '@material-ui/styles'
import Routes from 'routes'
import theme from './theme'
import TagManager from 'react-gtm-module'
import * as pixel from 'utils/pixel'

import firebase from 'firebase/compat/app'
import { initializeApp } from 'firebase/app'
import {
  getFunctions,
  connectFunctionsEmulator,
  httpsCallable
} from 'firebase/functions'

import { firebaseConfig } from 'config'
import { generateRandomId } from 'utils/generateRandomId'
import ErrorBoundary from 'components/ErrorBoundary/Component'

import './styles/common.sass'

const trackOnVisitEvents = async (functions: any) => {
  const addToCartEventId = generateRandomId()

  pixel.fbqTrackSingle('AddToCart', addToCartEventId)

  const onVisitChargifySignUpPage = httpsCallable(
    functions,
    'onVisitChargifySignUpPage'
  )
  await onVisitChargifySignUpPage({ addToCartEventId })
}

const App: React.FC = () => {
  if (!firebase.apps.length) {
    const firebaseApp = initializeApp(firebaseConfig)
    const functions = getFunctions(firebaseApp)
    if (
      JSON.parse(process.env.REACT_APP_FIREBASE_USE_LOCAL_EMULATOR!!) === true
    ) {
      connectFunctionsEmulator(functions, 'localhost', 5001)
    }
    trackOnVisitEvents(functions)
  }

  TagManager.initialize({
    gtmId: 'GTM-W4QVHX6',
    auth: 'r0e-Ry99rPBmH4k5IXqFQw',
    preview: 'env-2'
  })

  return (
    <ErrorBoundary>
      <ThemeProvider theme={theme}>
        <Routes />
      </ThemeProvider>
    </ErrorBoundary>
  )
}

export default App
