declare global {
  interface Window {
    fbpLoaded: Promise<undefined>
    fbq: Function
  }
}

// See: https://developers.facebook.com/docs/marketing-api/conversions-api/deduplicate-pixel-and-server-events

/**
 *
 * @param eventName Name of event such as Purchase or PageView
 * @param eventId Unique id for event
 * @param eventData Object containing event data such as {value: 12, currency: 'USD'}
 */
export async function fbqTrackSingle(
  eventName: string,
  eventId: string,
  eventData?: any
): Promise<void> {
  await window.fbpLoaded
  window.fbq(
    'trackSingle',
    process.env.REACT_APP_PIXEL_ID_2218,
    eventName,
    eventData ?? {},
    { eventID: eventId }
  )
}
